const ticketContractAbi = [{
    "inputs": [
        {
            "internalType": "string",
            "name": "_name",
            "type": "string"
        },
        {
            "internalType": "string",
            "name": "_symbol",
            "type": "string"
        },
        {
            "internalType": "uint8",
            "name": "decimals_",
            "type": "uint8"
        },
        {
            "internalType": "address",
            "name": "_controller",
            "type": "address"
        }
    ],
    "stateMutability": "nonpayable",
    "type": "constructor"
},
{
    "anonymous": false,
    "inputs": [
        {
            "indexed": true,
            "internalType": "address",
            "name": "owner",
            "type": "address"
        },
        {
            "indexed": true,
            "internalType": "address",
            "name": "spender",
            "type": "address"
        },
        {
            "indexed": false,
            "internalType": "uint256",
            "name": "value",
            "type": "uint256"
        }
    ],
    "name": "Approval",
    "type": "event"
},
{
    "anonymous": false,
    "inputs": [
        {
            "indexed": true,
            "internalType": "address",
            "name": "delegator",
            "type": "address"
        },
        {
            "indexed": true,
            "internalType": "address",
            "name": "delegate",
            "type": "address"
        }
    ],
    "name": "Delegated",
    "type": "event"
},
{
    "anonymous": false,
    "inputs": [
        {
            "indexed": false,
            "internalType": "string",
            "name": "name",
            "type": "string"
        },
        {
            "indexed": false,
            "internalType": "string",
            "name": "symbol",
            "type": "string"
        },
        {
            "indexed": false,
            "internalType": "uint8",
            "name": "decimals",
            "type": "uint8"
        },
        {
            "indexed": true,
            "internalType": "address",
            "name": "controller",
            "type": "address"
        }
    ],
    "name": "Deployed",
    "type": "event"
},
{
    "anonymous": false,
    "inputs": [
        {
            "components": [
                {
                    "internalType": "uint224",
                    "name": "amount",
                    "type": "uint224"
                },
                {
                    "internalType": "uint32",
                    "name": "timestamp",
                    "type": "uint32"
                }
            ],
            "indexed": false,
            "internalType": "struct ObservationLib.Observation",
            "name": "newTotalSupplyTwab",
            "type": "tuple"
        }
    ],
    "name": "NewTotalSupplyTwab",
    "type": "event"
},
{
    "anonymous": false,
    "inputs": [
        {
            "indexed": true,
            "internalType": "address",
            "name": "delegate",
            "type": "address"
        },
        {
            "components": [
                {
                    "internalType": "uint224",
                    "name": "amount",
                    "type": "uint224"
                },
                {
                    "internalType": "uint32",
                    "name": "timestamp",
                    "type": "uint32"
                }
            ],
            "indexed": false,
            "internalType": "struct ObservationLib.Observation",
            "name": "newTwab",
            "type": "tuple"
        }
    ],
    "name": "NewUserTwab",
    "type": "event"
},
{
    "anonymous": false,
    "inputs": [
        {
            "indexed": false,
            "internalType": "string",
            "name": "name",
            "type": "string"
        },
        {
            "indexed": false,
            "internalType": "string",
            "name": "symbol",
            "type": "string"
        },
        {
            "indexed": false,
            "internalType": "uint8",
            "name": "decimals",
            "type": "uint8"
        },
        {
            "indexed": true,
            "internalType": "address",
            "name": "controller",
            "type": "address"
        }
    ],
    "name": "TicketInitialized",
    "type": "event"
},
{
    "anonymous": false,
    "inputs": [
        {
            "indexed": true,
            "internalType": "address",
            "name": "from",
            "type": "address"
        },
        {
            "indexed": true,
            "internalType": "address",
            "name": "to",
            "type": "address"
        },
        {
            "indexed": false,
            "internalType": "uint256",
            "name": "value",
            "type": "uint256"
        }
    ],
    "name": "Transfer",
    "type": "event"
},
{
    "inputs": [],
    "name": "DOMAIN_SEPARATOR",
    "outputs": [
        {
            "internalType": "bytes32",
            "name": "",
            "type": "bytes32"
        }
    ],
    "stateMutability": "view",
    "type": "function"
},
{
    "inputs": [
        {
            "internalType": "address",
            "name": "owner",
            "type": "address"
        },
        {
            "internalType": "address",
            "name": "spender",
            "type": "address"
        }
    ],
    "name": "allowance",
    "outputs": [
        {
            "internalType": "uint256",
            "name": "",
            "type": "uint256"
        }
    ],
    "stateMutability": "view",
    "type": "function"
},
{
    "inputs": [
        {
            "internalType": "address",
            "name": "spender",
            "type": "address"
        },
        {
            "internalType": "uint256",
            "name": "amount",
            "type": "uint256"
        }
    ],
    "name": "approve",
    "outputs": [
        {
            "internalType": "bool",
            "name": "",
            "type": "bool"
        }
    ],
    "stateMutability": "nonpayable",
    "type": "function"
},
{
    "inputs": [
        {
            "internalType": "address",
            "name": "account",
            "type": "address"
        }
    ],
    "name": "balanceOf",
    "outputs": [
        {
            "internalType": "uint256",
            "name": "",
            "type": "uint256"
        }
    ],
    "stateMutability": "view",
    "type": "function"
},
{
    "inputs": [],
    "name": "controller",
    "outputs": [
        {
            "internalType": "address",
            "name": "",
            "type": "address"
        }
    ],
    "stateMutability": "view",
    "type": "function"
},
{
    "inputs": [
        {
            "internalType": "address",
            "name": "_user",
            "type": "address"
        },
        {
            "internalType": "uint256",
            "name": "_amount",
            "type": "uint256"
        }
    ],
    "name": "controllerBurn",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
},
{
    "inputs": [
        {
            "internalType": "address",
            "name": "_operator",
            "type": "address"
        },
        {
            "internalType": "address",
            "name": "_user",
            "type": "address"
        },
        {
            "internalType": "uint256",
            "name": "_amount",
            "type": "uint256"
        }
    ],
    "name": "controllerBurnFrom",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
},
{
    "inputs": [
        {
            "internalType": "address",
            "name": "_user",
            "type": "address"
        },
        {
            "internalType": "address",
            "name": "_to",
            "type": "address"
        }
    ],
    "name": "controllerDelegateFor",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
},
{
    "inputs": [
        {
            "internalType": "address",
            "name": "_user",
            "type": "address"
        },
        {
            "internalType": "uint256",
            "name": "_amount",
            "type": "uint256"
        }
    ],
    "name": "controllerMint",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
},
{
    "inputs": [],
    "name": "decimals",
    "outputs": [
        {
            "internalType": "uint8",
            "name": "",
            "type": "uint8"
        }
    ],
    "stateMutability": "view",
    "type": "function"
},
{
    "inputs": [
        {
            "internalType": "address",
            "name": "spender",
            "type": "address"
        },
        {
            "internalType": "uint256",
            "name": "subtractedValue",
            "type": "uint256"
        }
    ],
    "name": "decreaseAllowance",
    "outputs": [
        {
            "internalType": "bool",
            "name": "",
            "type": "bool"
        }
    ],
    "stateMutability": "nonpayable",
    "type": "function"
},
{
    "inputs": [
        {
            "internalType": "address",
            "name": "_to",
            "type": "address"
        }
    ],
    "name": "delegate",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
},
{
    "inputs": [
        {
            "internalType": "address",
            "name": "_user",
            "type": "address"
        }
    ],
    "name": "delegateOf",
    "outputs": [
        {
            "internalType": "address",
            "name": "",
            "type": "address"
        }
    ],
    "stateMutability": "view",
    "type": "function"
},
{
    "inputs": [
        {
            "internalType": "address",
            "name": "_user",
            "type": "address"
        },
        {
            "internalType": "address",
            "name": "_newDelegate",
            "type": "address"
        },
        {
            "internalType": "uint256",
            "name": "_deadline",
            "type": "uint256"
        },
        {
            "internalType": "uint8",
            "name": "_v",
            "type": "uint8"
        },
        {
            "internalType": "bytes32",
            "name": "_r",
            "type": "bytes32"
        },
        {
            "internalType": "bytes32",
            "name": "_s",
            "type": "bytes32"
        }
    ],
    "name": "delegateWithSignature",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
},
{
    "inputs": [
        {
            "internalType": "address",
            "name": "_user",
            "type": "address"
        }
    ],
    "name": "getAccountDetails",
    "outputs": [
        {
            "components": [
                {
                    "internalType": "uint208",
                    "name": "balance",
                    "type": "uint208"
                },
                {
                    "internalType": "uint24",
                    "name": "nextTwabIndex",
                    "type": "uint24"
                },
                {
                    "internalType": "uint24",
                    "name": "cardinality",
                    "type": "uint24"
                }
            ],
            "internalType": "struct TwabLib.AccountDetails",
            "name": "",
            "type": "tuple"
        }
    ],
    "stateMutability": "view",
    "type": "function"
},
{
    "inputs": [
        {
            "internalType": "address",
            "name": "_user",
            "type": "address"
        },
        {
            "internalType": "uint64",
            "name": "_startTime",
            "type": "uint64"
        },
        {
            "internalType": "uint64",
            "name": "_endTime",
            "type": "uint64"
        }
    ],
    "name": "getAverageBalanceBetween",
    "outputs": [
        {
            "internalType": "uint256",
            "name": "",
            "type": "uint256"
        }
    ],
    "stateMutability": "view",
    "type": "function"
},
{
    "inputs": [
        {
            "internalType": "address",
            "name": "_user",
            "type": "address"
        },
        {
            "internalType": "uint64[]",
            "name": "_startTimes",
            "type": "uint64[]"
        },
        {
            "internalType": "uint64[]",
            "name": "_endTimes",
            "type": "uint64[]"
        }
    ],
    "name": "getAverageBalancesBetween",
    "outputs": [
        {
            "internalType": "uint256[]",
            "name": "",
            "type": "uint256[]"
        }
    ],
    "stateMutability": "view",
    "type": "function"
},
{
    "inputs": [
        {
            "internalType": "uint64[]",
            "name": "_startTimes",
            "type": "uint64[]"
        },
        {
            "internalType": "uint64[]",
            "name": "_endTimes",
            "type": "uint64[]"
        }
    ],
    "name": "getAverageTotalSuppliesBetween",
    "outputs": [
        {
            "internalType": "uint256[]",
            "name": "",
            "type": "uint256[]"
        }
    ],
    "stateMutability": "view",
    "type": "function"
},
{
    "inputs": [
        {
            "internalType": "address",
            "name": "_user",
            "type": "address"
        },
        {
            "internalType": "uint64",
            "name": "_target",
            "type": "uint64"
        }
    ],
    "name": "getBalanceAt",
    "outputs": [
        {
            "internalType": "uint256",
            "name": "",
            "type": "uint256"
        }
    ],
    "stateMutability": "view",
    "type": "function"
},
{
    "inputs": [
        {
            "internalType": "address",
            "name": "_user",
            "type": "address"
        },
        {
            "internalType": "uint64[]",
            "name": "_targets",
            "type": "uint64[]"
        }
    ],
    "name": "getBalancesAt",
    "outputs": [
        {
            "internalType": "uint256[]",
            "name": "",
            "type": "uint256[]"
        }
    ],
    "stateMutability": "view",
    "type": "function"
},
{
    "inputs": [
        {
            "internalType": "uint64[]",
            "name": "_targets",
            "type": "uint64[]"
        }
    ],
    "name": "getTotalSuppliesAt",
    "outputs": [
        {
            "internalType": "uint256[]",
            "name": "",
            "type": "uint256[]"
        }
    ],
    "stateMutability": "view",
    "type": "function"
},
{
    "inputs": [
        {
            "internalType": "uint64",
            "name": "_target",
            "type": "uint64"
        }
    ],
    "name": "getTotalSupplyAt",
    "outputs": [
        {
            "internalType": "uint256",
            "name": "",
            "type": "uint256"
        }
    ],
    "stateMutability": "view",
    "type": "function"
},
{
    "inputs": [
        {
            "internalType": "address",
            "name": "_user",
            "type": "address"
        },
        {
            "internalType": "uint16",
            "name": "_index",
            "type": "uint16"
        }
    ],
    "name": "getTwab",
    "outputs": [
        {
            "components": [
                {
                    "internalType": "uint224",
                    "name": "amount",
                    "type": "uint224"
                },
                {
                    "internalType": "uint32",
                    "name": "timestamp",
                    "type": "uint32"
                }
            ],
            "internalType": "struct ObservationLib.Observation",
            "name": "",
            "type": "tuple"
        }
    ],
    "stateMutability": "view",
    "type": "function"
},
{
    "inputs": [
        {
            "internalType": "address",
            "name": "spender",
            "type": "address"
        },
        {
            "internalType": "uint256",
            "name": "addedValue",
            "type": "uint256"
        }
    ],
    "name": "increaseAllowance",
    "outputs": [
        {
            "internalType": "bool",
            "name": "",
            "type": "bool"
        }
    ],
    "stateMutability": "nonpayable",
    "type": "function"
},
{
    "inputs": [],
    "name": "name",
    "outputs": [
        {
            "internalType": "string",
            "name": "",
            "type": "string"
        }
    ],
    "stateMutability": "view",
    "type": "function"
},
{
    "inputs": [
        {
            "internalType": "address",
            "name": "owner",
            "type": "address"
        }
    ],
    "name": "nonces",
    "outputs": [
        {
            "internalType": "uint256",
            "name": "",
            "type": "uint256"
        }
    ],
    "stateMutability": "view",
    "type": "function"
},
{
    "inputs": [
        {
            "internalType": "address",
            "name": "owner",
            "type": "address"
        },
        {
            "internalType": "address",
            "name": "spender",
            "type": "address"
        },
        {
            "internalType": "uint256",
            "name": "value",
            "type": "uint256"
        },
        {
            "internalType": "uint256",
            "name": "deadline",
            "type": "uint256"
        },
        {
            "internalType": "uint8",
            "name": "v",
            "type": "uint8"
        },
        {
            "internalType": "bytes32",
            "name": "r",
            "type": "bytes32"
        },
        {
            "internalType": "bytes32",
            "name": "s",
            "type": "bytes32"
        }
    ],
    "name": "permit",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
},
{
    "inputs": [],
    "name": "symbol",
    "outputs": [
        {
            "internalType": "string",
            "name": "",
            "type": "string"
        }
    ],
    "stateMutability": "view",
    "type": "function"
},
{
    "inputs": [],
    "name": "totalSupply",
    "outputs": [
        {
            "internalType": "uint256",
            "name": "",
            "type": "uint256"
        }
    ],
    "stateMutability": "view",
    "type": "function"
},
{
    "inputs": [
        {
            "internalType": "address",
            "name": "to",
            "type": "address"
        },
        {
            "internalType": "uint256",
            "name": "amount",
            "type": "uint256"
        }
    ],
    "name": "transfer",
    "outputs": [
        {
            "internalType": "bool",
            "name": "",
            "type": "bool"
        }
    ],
    "stateMutability": "nonpayable",
    "type": "function"
},
{
    "inputs": [
        {
            "internalType": "address",
            "name": "from",
            "type": "address"
        },
        {
            "internalType": "address",
            "name": "to",
            "type": "address"
        },
        {
            "internalType": "uint256",
            "name": "amount",
            "type": "uint256"
        }
    ],
    "name": "transferFrom",
    "outputs": [
        {
            "internalType": "bool",
            "name": "",
            "type": "bool"
        }
    ],
    "stateMutability": "nonpayable",
    "type": "function"
}
]
export default ticketContractAbi;
